import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { graphql } from 'gatsby';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "team-header"}) {
      name
      images: media {
        file {
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 1400, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const HeaderImage = makeShortcode("HeaderImage");
const Img = makeShortcode("Img");
const Wrapper = makeShortcode("Wrapper");
const CTASection = makeShortcode("CTASection");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <HeaderImage mdxType="HeaderImage">
    <Img fluid={props.data.headerImages.images[0].file.localFile.childImageSharp.fluid} alt='Team' mdxType="Img" />
    </HeaderImage>
    <h1>{`Über uns`}</h1>
    <Wrapper mdxType="Wrapper">
      <h2 {...{
        "id": "team"
      }}>{`Team`}</h2>
      <p>{`Unser fachlich versiertes Team berät Sie gern!`}</p>
      <TeamMembers layout={'grid'} mdxType="TeamMembers" />
      <h2 {...{
        "id": "philosophie"
      }}>{`Philosophie`}</h2>
      <h3 {...{
        "id": "design-trifft-qualitat"
      }}>{`Design trifft Qualität`}</h3>
      <p>{`Wir möchten unseren Kunden ein ganz besonderes Einkaufserlebnis bieten. Im Geschäft schaffen ein inspirierendes Ambiente, unsere kompetenten Mitarbeiter und unsere sorgfältig zusammengestellte Auswahl hochwertiger Schmuckstücke, Uhren und Trendkollektionen den Rahmen dafür.`}</p>
      <p>{`In ausführlichen Gesprächen erhalten Sie genau die Beratung, die Sie zu dem Schmuckstück führt, das Ihre Augen funkeln lässt.
Unsere hauseigene Kollektion wird von unseren Goldschmieden entworfen und in präziser Handarbeit gefertigt. In ihr zeigt sich unsere kompromisslose Begeisterung für die Verbindung von edlen Materialien, innovativem Design und meisterlicher Handwerkskunst.`}</p>
      <hr></hr>
      <h2 {...{
        "id": "historie"
      }}>{`Historie`}</h2>
      <h3 {...{
        "id": "aus-liebe-zum-schonen"
      }}>{`Aus Liebe zum Schönen`}</h3>
      <p>{`Im Jahr 1954 eröffneten Anneliese und Alfons Spieker das Juweliergeschäft mit Uhrmachermeisterwerkstatt. Zunächst im Hause Fleitmann eingegliedert, folgte 1964 der Umzug in den heutigen Stammsitz in der Schloßstraße 15. In den folgenden Jahrzehnten wurde das Angebot gezielt vergrößert, und das Unternehmen entwickelte sich im Zuge mehrerer Erweiterung bauten zur heutigen Größe. 1992 trat Alfons Spieker junior in den elterlichen Betrieb ein und übernahm als Goldschmiedemeister 1997 zusammen mit seiner Frau Andrea die Geschäftsführung.`}</p>
      <p>{`Die Eröffnung der „Goldschmiede am Schloss“ setzte im Jahr 1999 einen weiteren Meilenstein in der Geschichte von Juwelier Spieker.
In einem wohl einmaligen Ambiente werden hier hauseigene Kollektionen und individuelle Unikate auf Kundenwunsch erstellt.`}</p>
    </Wrapper>
    <hr></hr>
    <CTASection mdxType="CTASection" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      